import { isObject } from './isObject';

/**
 * The opposite of `_.pick`; this method creates an object composed of the
 * own and inherited enumerable properties of `object` that are not omitted.
 *
 * @category Object
 * @param object The source object.
 * @param [keys] The property name(s) to omit -- must be shallow.
 * @returns Returns the new object.
 * @example
 *
 * var object = { 'a': 1, 'b': '2', 'c': 3 };
 *
 * _.omit(object, 'a', 'c');
 * // => { 'b': '2' }
 */
export function omit<T extends object, K extends (keyof T)[]>(
  object: T | null | undefined,
  ...keys: K
): Pick<T, Exclude<keyof T, K[number]>> {
  if (object === null || object === undefined || !isObject(object)) return object as any;
  const [path, ...remainingPaths] = keys;
  if (!path) return object;

  const shallowClone = { ...object };
  delete shallowClone[path];
  return omit(shallowClone, ...(remainingPaths as K));
}
